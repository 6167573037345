
import React, { useEffect, useState } from "react";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, Table, Badge
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";


import Swal from 'sweetalert2';
import api from '../../services/api.service';
import { toast } from 'react-toastify';
const StrayPosition = () => {
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    var firestoreDb1 = firebase.firestore().collection("users").doc(uid);
    // const get_positions = firestoreDb1.collection("profile").doc("positions-info");
    const [transactionData, setTransactionData] = useState([]);
    const [form1_loader, setForm1Loader] = useState({ loading: false });
    const closePosition = async (product_id, size) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the position in delta exchange ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    product_id: product_id,
                    size: Math.abs(size),
                    side: size > 0 ? 'sell' : 'buy'
                };
                try {
                    await api({ contentType: true, auth: true }).post('/delta/close-stray-positions', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                            //setStopped(res.data.message);
                        }
                    });
                } catch (e) {
                    toast.error(e.message);
                }

            }
        });
    }
    const get_stray_orders = async () => {
        setForm1Loader({
            loading: true,
        });
        let item = [];
        const future_orders= await api({ contentType: true, auth: true }).get('/delta-hedging/delta-hedging-active-orders');
        // await get_positions.onSnapshot(async (doc) => {
            await api({ contentType: true, auth: true }).get('/delta/get-delta-positions').then(async(response) => {
               let res=response.data.response.data[0];
                let positions;
               
                if (res.positions.length > 0) {
                    positions = res.positions
                    let get_order = [];
                    await positions.forEach(async (position) => {
                        let symbol = position.product_symbol;
                        const check_diagonal = await firestoreDb1.collection("diagonal_orders").where("symbol",'==',symbol).get();
                        const check_strangle = await firestoreDb1.collection("strangle_orders").where("symbol",'==',symbol).get();
                        const check_straddle = await firestoreDb1.collection("straddle_orders").where("symbol",'==',symbol).get();
                        const check_trend = await firestoreDb1.collection("trend_orders").where("symbol",'==',symbol).get();
                        ;
                        if(symbol=="BTCUSDT" && future_orders.data.response.data.length==0){
                            get_order.push(position);
                            setTransactionData(get_order);
                            setForm1Loader({
                                loading: false,
                            });
                        }else if ((check_diagonal.docs.length == 0) && (check_strangle.docs.length == 0) && (check_straddle.docs.length == 0) && (check_trend.docs.length == 0) && symbol!=="BTCUSDT") {
                            console.log("inside" ,check_straddle.exists);
                            get_order.push(position);
                            setTransactionData(get_order);
                            setForm1Loader({
                                loading: false,
                            });
                        }
                        else {
                            setForm1Loader({
                                loading: false,
                            });
                        }
                    });
                } else {
                    setForm1Loader({
                        loading: false,
                    });
                }

            }).catch((error) => {
                setForm1Loader({
                    loading: false,
                });
            });


        // })


    }



    useEffect(() => {
        get_stray_orders();
    }, []);
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="DeriSnap" breadcrumbItem="Stray Positions" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">


                                       
                                        <Row className="strangle-box">
                                            <Col xs>
                                                <Card className="mb-4">
                                                    <CardBody>
                                                        <Table align="middle" className="mb-0 border" hover responsive  >
                                                            <thead>
                                                                <tr>
                                                                    <th>Symbol</th>
                                                                    <th style={{ textAlign: "center" }}>Position Size</th>
                                                                    <th style={{ textAlign: "center" }}>Entry Price</th>
                                                                    <th style={{ textAlign: "center" }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transactionData && transactionData.length > 0 ? (
                                                                    transactionData.map((row, index) => {
                                                                        return (<tr className="trow-hover" key={index}>
                                                                            <td>
                                                                                <Badge color={row.size > 0 ? "success" : "danger"}>{row.product_symbol}</Badge>
                                                                            </td>
                                                                            <td style={{ textAlign: "center" }}>{Number(row.size).toFixed(0)}</td>
                                                                            <td style={{ textAlign: "center" }}>{Number(row.entry_price).toFixed(2)}</td>
                                                                            <td style={{ textAlign: "center" }}> <button
                                                                                type="button" 
                                                                                onClick={() =>
                                                                                    closePosition(row.product_id, row.size)
                                                                                }
                                                                                className="btn btn-danger btn-sm"
                                                                            >
                                                                                Close Position
                                                                            </button></td>
                                                                        </tr>)
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={11} style={{ textAlign: "center" }}>
                                                                            {form1_loader.loading ? 'Loading...' : 'No Data Found'}
                                                                        </td>
                                                                    </tr>

                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );

}

export default StrayPosition;